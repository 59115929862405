export const LIST_TRANSACTION_INITIATED = "LIST_TRANSACTION_INITIATED";
export const LIST_TRANSACTION_SUCCESS = "LIST_TRANSACTION_SUCCESS";
export const LIST_TRANSACTION_FAILED = "LIST_TRANSACTION_FAILED";

/*---  Refund EndPoint  ----*/
export const LIST_REFUND_INITIATED = "LIST_REFUND_INITIATED";
export const LIST_REFUND_SUCCESS = "LIST_REFUND_SUCCESS";
export const LIST_REFUND_FAILED = "LIST_REFUND_FAILED";

/*---  Refund History EndPoint  ----*/
export const LIST_REFUND_HISTORY_INITIATED = "LIST_REFUND_HISTORY_INITIATED";
export const LIST_REFUND_HISTORY_SUCCESS = "LIST_REFUND_HISTORY_SUCCESS";
export const LIST_REFUND_HISTORY_FAILED = "LIST_REFUND_HISTORY_FAILED";

export const LIST_HISTORY_TRANSACTION_INITIATED = "LIST_HISTORY_TRANSACTION_INITIATED";
export const LIST_HISTORY_TRANSACTION_SUCCESS = "LIST_HISTORY_TRANSACTION_SUCCESS";
export const LIST_HISTORY_TRANSACTION_FAILED = "LIST_HISTORY_TRANSACTION_FAILED";

export const EXPORT_REFUND_HISTORY_INITIATED = "EXPORT_REFUND_HISTORY_INITIATED";
export const EXPORT_REFUND_HISTORY_SUCCESS = "EXPORT_REFUND_HISTORY_SUCCESS";
export const EXPORT_REFUND_HISTORY_FAILED = "EXPORT_REFUND_HISTORY_FAILED";
