import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];
  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        const accessToken = this.getToken();
        if (accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error;
        if (
          response &&
          response.status === 401 &&
          !config.url.includes("/login")
        ) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );
  }

  /* ---  Auth ----- */

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }

  /*------------- Admin ------------- */

  getProfile() {
    return axios.get(`${this.jwtConfig.getProfileEndPoint}`);
  }

  updatePassword(data) {
    return axios.patch(`${this.jwtConfig.updatePasswordEndPoint}`, data);
  }

  updateProfile(data) {
    return axios.patch(`${this.jwtConfig.updateProfileEndPoint}`, data);
  }

  getAdminsList({ role = "admin", page = 1, limit = 10, searchKeyword }) {
    let endPoint = `${this.jwtConfig.getListAdminEndPoint}?role=${role}&page=${page}&limit=${limit}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    return axios.get(endPoint);
  }

  createAdmin(data) {
    return axios.post(`${this.jwtConfig.createAdminEndPoint}`, data);
  }

  updateAdmin(data, id) {
    return axios.patch(`${this.jwtConfig.createAdminEndPoint}/${id}`, data);
  }

  deleteAdmin(id) {
    return axios.delete(`${this.jwtConfig.createAdminEndPoint}/${id}`);
  }

  /*--- Location --------*/

  getLocationList({
    searchKeyword,
    page = 1,
    limit = 10,
    cashChangeScreenStatus,
  }) {
    let endPoint = `${this.jwtConfig.getListLocationEndPoint}?page=${page}&limit=${limit}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    if (cashChangeScreenStatus)
      endPoint += `&cashChangeScreenStatus=${cashChangeScreenStatus}`;
    return axios.get(endPoint);
  }

  getLocationExtras() {
    return axios.get(`${this.jwtConfig.getLocationExtraEndPoint}`);
  }

  createLocation(data) {
    return axios.post(`${this.jwtConfig.locationEndPoint}`, data);
  }

  deleteLocation(id) {
    return axios.delete(`${this.jwtConfig.locationEndPoint}/${id}`);
  }

  updateLocation(data, id) {
    return axios.patch(`${this.jwtConfig.locationEndPoint}/${id}`, data);
  }

  exportAllLocation() {
    return axios.get(`${this.jwtConfig.locationExportEndpoint}`);
  }

  /* ------------- Cashier --------------------*/

  getCashierList({ location, searchKeyword, page = 1, limit = 10 }) {
    let endPoint = `${this.jwtConfig.getListCashierEndPoint}?page=${page}&limit=${limit}`;
    if (location) endPoint += `&location=${location}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    return axios.get(endPoint);
  }

  createCashier(data) {
    return axios.post(`${this.jwtConfig.cashierEndPoint}`, data);
  }

  updateCashier(data, id) {
    return axios.patch(`${this.jwtConfig.cashierEndPoint}${id}`, data);
  }

  deleteCashier(id) {
    return axios.delete(`${this.jwtConfig.cashierEndPoint}${id}`);
  }

  exportAllCashier() {
    return axios.get(`${this.jwtConfig.cashierExportEndpoint}`);
  }

  /* ------- Category ------*/

  getCategoryList({ type, parent, searchKeyword, page = 1, limit = 10 }) {
    let endPoint = `${this.jwtConfig.getCategoryListEndPoint}?page=${page}&limit=${limit}`;
    if (type) endPoint += `&type=${type}`;
    if (parent) endPoint += `&parent=${parent}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    return axios.get(endPoint);
  }

  createCategory(data) {
    return axios.post(`${this.jwtConfig.categoryEndPoint}`, data);
  }

  updateCategory(data, id) {
    return axios.patch(`${this.jwtConfig.categoryEndPoint}/${id}`, data);
  }

  deleteCategory(id) {
    return axios.delete(`${this.jwtConfig.categoryEndPoint}/${id}`);
  }

  /* ----- Products ----- */

  getProductList({
    category,
    subCategory,
    location,
    searchKeyword,
    kitchenFilter,
    page = 1,
    limit = 10,
  }) {
    let endPoint = `${this.jwtConfig.getListProductEndPoint}?page=${page}&limit=${limit}`;
    if (category) endPoint += `&category=${category}`;
    if (subCategory) endPoint += `&subCategory=${subCategory}`;
    if (location) endPoint += `&location=${location}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    if (kitchenFilter) endPoint += `&kitchen=${kitchenFilter}`;
    return axios.get(endPoint);
  }

  createProduct(data) {
    return axios.post(`${this.jwtConfig.productEndPoint}`, data);
  }

  updateProduct(data, id) {
    return axios.patch(`${this.jwtConfig.productEndPoint}/${id}`, data);
  }

  deleteProduct(id) {
    return axios.delete(`${this.jwtConfig.productEndPoint}/${id}`);
  }

  /* ------- Events ------ */
  getEventList({
    location,
    searchKeyword,
    category,
    mode,
    page = 1,
    limit = 10,
  }) {
    let endPoint = `${this.jwtConfig.getEventProductEndPoint}?page=${page}&limit=${limit}`;
    if (location) endPoint += `&location=${location}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    if (category) endPoint += `&category=${category}`;
    if (mode) endPoint += `&mode=${mode}`;
    return axios.get(endPoint);
  }

  createEvent(data) {
    return axios.post(`${this.jwtConfig.eventEndoint}`, data);
  }

  updateEvent(data, id) {
    return axios.patch(`${this.jwtConfig.eventEndoint}/${id}`, data);
  }

  deleteEvent(id) {
    return axios.delete(`${this.jwtConfig.eventEndoint}/${id}`);
  }

  /* ------- Tables ------ */
  getTableList({ location, searchKeyword, status, page = 1, limit = 10 }) {
    let endPoint = `${this.jwtConfig.getTableProductEndPoint}?page=${page}&limit=${limit}`;
    if (location) endPoint += `&location=${location}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    if (status) endPoint += `&status=${status}`;
    return axios.get(endPoint);
  }

  createTable(data) {
    return axios.post(`${this.jwtConfig.tableEndoint}`, data);
  }

  createTableBulk(data) {
    return axios.post(`${this.jwtConfig.tableBulkEndoint}`, data);
  }

  updateTable(data, id) {
    return axios.patch(`${this.jwtConfig.tableEndoint}/${id}`, data);
  }

  deleteTable(id) {
    return axios.delete(`${this.jwtConfig.tableEndoint}/${id}`);
  }

  /* ------- Accessories ------ */
  getAccessoriesList({ location, searchKeyword, type, page = 1, limit = 10 }) {
    let endPoint = `${this.jwtConfig.getAccessoriesEndoint}?page=${page}&limit=${limit}`;
    if (location) endPoint += `&location=${location}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    if (type) endPoint += `&type=${type}`;
    return axios.get(endPoint);
  }

  createAccessories(data) {
    return axios.post(`${this.jwtConfig.accessoriesEndoint}`, data);
  }

  updateAccessories(data, id) {
    return axios.patch(`${this.jwtConfig.accessoriesEndoint}/${id}`, data);
  }

  deleteAccessories(id) {
    return axios.delete(`${this.jwtConfig.accessoriesEndoint}/${id}`);
  }

  /* -------regular Orders ------*/
  getOrders({
    page = 1,
    limit = 10,
    paymentMethod,
    type,
    subType,
    user,
    orderedAtFrom,
    orderedAtTo,
    locationFilter,
  }) {
    let endPoint = `${this.jwtConfig.getOrderEndPoint}?page=${page}&limit=${limit}`;
    if (paymentMethod) endPoint += `&paymentMethod=${paymentMethod}`;
    if (orderedAtFrom) endPoint += `&orderedAtFrom=${orderedAtFrom}`;
    if (orderedAtTo) endPoint += `&orderedAtTo=${orderedAtTo}`;
    if (subType) endPoint += `&subType=${subType}`;
    if (type) endPoint += `&type=${type}`;
    if (locationFilter) endPoint += `&location=${locationFilter}`;
    if (user) endPoint += `&user=${user}`;
    return axios.get(endPoint);
  }

  /* ------- Freeze Orders ------*/
  getFreezeOrdersList({
    page = 1,
    limit = 10,
    type,
    subType,
    location,
    freezedAtFrom,
    freezedAtTo,
    user,
  }) {
    let endPoint = `${this.jwtConfig.getFreezeOrderEndPoint}?page=${page}&limit=${limit}`;
    if (type) endPoint += `&type=${type}`;
    if (subType) endPoint += `&subType=${subType}`;
    if (location) endPoint += `&location=${location}`;
    if (freezedAtFrom) endPoint += `&freezedAtFrom=${freezedAtFrom}`;
    if (freezedAtTo) endPoint += `&freezedAtTo=${freezedAtTo}`;
    if (user) endPoint += `&user=${user}`;
    return axios.get(endPoint);
  }

  /* ------- Transactions ------- */

  getTransactions({
    id,
    status,
    locationFilter,
    cashierFilter,
    paymentMethod,
    createdBy,
    cardOperation,
    type,
    page = 1,
    limit = 10,
    orderedAtFrom,
    orderedAtTo,
  }) {
    let endPoint = `${this.jwtConfig.getTransactionsEndPoint}?page=${page}&limit=${limit}`;
    if (id) endPoint += `&card=${id}`;
    if (status) endPoint += `&status=${status}`;
    if (locationFilter) endPoint += `&location=${locationFilter}`;
    if (type) endPoint += `&type=${type}`;
    if (cashierFilter) endPoint += `&user=${cashierFilter}`;
    if (paymentMethod) endPoint += `&paymentMethod=${paymentMethod}`;
    if (createdBy) endPoint += `&createdBy=${createdBy}`;
    if (orderedAtFrom) endPoint += `&orderedAtFrom=${orderedAtFrom}`;
    if (orderedAtTo) endPoint += `&orderedAtTo=${orderedAtTo}`;
    if (cardOperation) endPoint += `&cardOperation=${cardOperation}`;
    return axios.get(endPoint);
  }

  getRefundList({
    status,
    locationFilter,
    cashierFilter,
    paymentMethod,
    createdBy,
    page = 1,
    limit = 10,
  }) {
    let endPoint = `${this.jwtConfig.getRefundEndPoint}&page=${page}&limit=${limit}`;
    if (status) endPoint += `&status=${status}`;
    if (locationFilter) endPoint += `&location=${locationFilter}`;
    if (cashierFilter) endPoint += `&user=${cashierFilter}`;
    if (paymentMethod) endPoint += `&paymentMethod=${paymentMethod}`;
    if (createdBy) endPoint += `&createdBy=${createdBy}`;
    return axios.get(endPoint);
  }

  getRefundHistoryList({
    status,
    locationFilter,
    cashierFilter,
    paymentMethod,
    createdBy,
    page = 1,
    limit = 10,
  }) {
    let endPoint = `${this.jwtConfig.getRefundHistoryEndPoint}?page=${page}&limit=${limit}`;
    if (status) endPoint += `&status=${status}`;
    if (locationFilter) endPoint += `&location=${locationFilter}`;
    if (cashierFilter) endPoint += `&user=${cashierFilter}`;
    if (paymentMethod) endPoint += `&paymentMethod=${paymentMethod}`;
    if (createdBy) endPoint += `&createdBy=${createdBy}`;
    return axios.get(endPoint);
  }

  getRefundHistoryTransactions({
    id,
    status,
    locationFilter,
    cashierFilter,
    paymentMethod,
    createdBy,
    cardOperation,
    type,
    page = 1,
    limit = 10,
    orderedAtFrom,
    orderedAtTo,
  }) {
    let endPoint = `${this.jwtConfig.getTransactionsEndPoint}?page=${page}&limit=${limit}`;
    if (id) endPoint += `&refundHistory=${id}`;
    if (status) endPoint += `&status=${status}`;
    if (locationFilter) endPoint += `&location=${locationFilter}`;
    if (type) endPoint += `&type=${type}`;
    if (cashierFilter) endPoint += `&user=${cashierFilter}`;
    if (paymentMethod) endPoint += `&paymentMethod=${paymentMethod}`;
    if (createdBy) endPoint += `&createdBy=${createdBy}`;
    if (orderedAtFrom) endPoint += `&orderedAtFrom=${orderedAtFrom}`;
    if (orderedAtTo) endPoint += `&orderedAtTo=${orderedAtTo}`;
    if (cardOperation) endPoint += `&cardOperation=${cardOperation}`;
    return axios.get(endPoint);
  }

  /* ------- Card ------*/

  getCardList({
    page = 1,
    limit = 10,
    searchKeyword,
    purchasedStatus,
    status,
    type,
  }) {
    let endPoint = `${this.jwtConfig.getCardEndPoint}?page=${page}&limit=${limit}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    if (purchasedStatus) endPoint += `&purchaseStatus=${purchasedStatus}`;
    if (type) endPoint += `&type=${type}`;
    if (status) endPoint += `&status=${status}`;
    return axios.get(endPoint);
  }

  createCard(data) {
    return axios.post(`${this.jwtConfig.createCard}`, data);
  }

  deleteCard(id) {
    return axios.delete(`${this.jwtConfig.createCard}/${id}`);
  }

  updateCard(data, id) {
    return axios.patch(`${this.jwtConfig.createCard}/${id}`, data);
  }

  /* ------- Kitchen ------*/

  getKitchenList({ page = 1, limit = 10, searchKeyword }) {
    let endPoint = `${this.jwtConfig.getKitchenEndpoint}?page=${page}&limit=${limit}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    return axios.get(endPoint);
  }

  createKitchen(data) {
    return axios.post(`${this.jwtConfig.createKitchenEndpoint}`, data);
  }

  updateKitchen(data, id) {
    return axios.patch(`${this.jwtConfig.createKitchenEndpoint}/${id}`, data);
  }

  deleteKitchen(id) {
    return axios.delete(`${this.jwtConfig.createKitchenEndpoint}/${id}`);
  }

  /*--------- Stats -----------*/
  getStat({ from = null, to = null }) {
    let endPoint = `${this.jwtConfig.getStatEndPoint}?`;
    if (from) endPoint += `&from=${from}`;
    if (to) endPoint += `&to=${to}`;
    return axios.get(`${endPoint}`);
  }

  /* ------- Order stats ------*/
  getOrderSaleStats({
    type,
    subType,
    orderedAtFrom,
    paymentMethod,
    orderedAtTo,
  }) {
    let endPoint = `${this.jwtConfig.getSaleStatsEndPoint}?`;
    if (type) endPoint += `type=${type}`;
    if (subType) endPoint += `&subType=${subType}`;
    if (paymentMethod) endPoint += `&paymentMethod=${paymentMethod}`;
    if (orderedAtFrom) endPoint += `&orderedAtFrom=${orderedAtFrom}`;
    if (orderedAtTo) endPoint += `&orderedAtTo=${orderedAtTo}`;
    return axios.get(endPoint);
  }

  /* ------- Cashier sales report ------*/
  getCashierSaleList({ orderedAtFrom, orderedAtTo }) {
    let endPoint = `${this.jwtConfig.getCashierSalesEndPoint}?`;
    if (orderedAtFrom) endPoint += `&orderedAtFrom=${orderedAtFrom}`;
    if (orderedAtTo) endPoint += `&orderedAtTo=${orderedAtTo}`;
    return axios.get(endPoint);
  }

  /* ------ User Sales Export ------*/
  exportUserSalesStats(data) {
    return axios.get(
      `${this.jwtConfig.getUserSalesEndPoint}cashGiven=${data.cashGiven}&coinsGiven=${data.coinsGiven}&user=${data.user}`
    );
  }

  /* -------  Product Sales List ------*/
  getProductListSales({
    orderedAtFrom,
    orderedAtTo,
    locationFilter,
    cashierFilter,
    type,
  }) {
    let endPoint = `${this.jwtConfig.getProductsaleStatsEndPoint}?`;
    if (locationFilter) endPoint += `&location=${locationFilter}`;
    if (type) endPoint += `&type=${type}`;
    if (cashierFilter) endPoint += `&user=${cashierFilter}`;
    if (orderedAtFrom) endPoint += `&orderedAtFrom=${orderedAtFrom}`;
    if (orderedAtTo) endPoint += `&orderedAtTo=${orderedAtTo}`;
    return axios.get(endPoint);
  }

  /* -------  Hourly Product Sales List ------*/
  getHourlyProductListSales({ type, event, date, product }) {
    let endPoint = `${this.jwtConfig.getHourlyProductsaleStatsEndPoint}?`;
    if (type) endPoint += `&type=${type}`;
    if (event) endPoint += `&event=${event}`;
    if (product) endPoint += `&product=${product}`;
    if (date) endPoint += `&date=${date}`;
    return axios.get(endPoint);
  }

  /* getDashBorad Stats -----*/

  getOrderStatsDash() {
    const currDate = new Date().toISOString().split("T")[0];
    return axios.get(
      `${this.jwtConfig.getSaleStatsEndPoint}?orderedAtFrom=${currDate}&daily=true`
    );
  }

  getTerminalList() {
    return axios.get(`${this.jwtConfig.getTerminalEndPoint}`);
  }

  /*---- Tickets ----*/

  getTicketList({ page = 1, limit = 10, searchKeyword }) {
    let endPoint = `${this.jwtConfig.getTicketListEndPoint}?page=${page}&limit=${limit}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    return axios.get(endPoint);
  }

  getTicketListNew({
    page = 1,
    limit = 10,
    searchKeyword,
    event,
    redeemed,
    from,
    to,
  }) {
    let endPoint = `${this.jwtConfig.getTicketsListNewEndPoint}/list/all?page=${page}&limit=${limit}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    if (event) endPoint += `&event=${event}`;
    if (redeemed) endPoint += `&redeemed=${redeemed}`;
    if (from) endPoint += `&from=${from}`;
    if (to) endPoint += `&to=${to}`;
    return axios.get(endPoint);
  }

  updateTicket(data, id) {
    return axios.patch(`${this.jwtConfig.UpdateTicketEndPoint}/${id}`, data);
  }

  deleteTicket(id) {
    return axios.delete(`${this.jwtConfig.DeleteTicketEndPoint}${id}`);
  }

  /* --- Get collection tool Stats List  --- */

  getCollectionToolList({
    orderedAtFrom,
    orderedAtTo,
    locationFilter,
    cashierFilter,
    type,
  }) {
    let endPoint = `${this.jwtConfig.CorrectionToolEndPoint}?`;
    if (locationFilter) endPoint += `&location=${locationFilter}`;
    if (type) endPoint += `&type=${type}`;
    if (cashierFilter) endPoint += `&user=${cashierFilter}`;
    if (orderedAtFrom) endPoint += `&orderedAtFrom=${orderedAtFrom}`;
    if (orderedAtTo) endPoint += `&orderedAtTo=${orderedAtTo}`;
    return axios.get(endPoint);
  }
  updateCollectionTool(data) {
    return axios.patch(`${this.jwtConfig.CorrectionToolEndPoint}`, data);
  }

  /* --- RFID Tool List  --- */

  getRfidToolList({
    orderedAtFrom,
    orderedAtTo,
    createdBy,
    locationFilter,
    cashierFilter,
  }) {
    let endPoint = `${this.jwtConfig.RfidToolEndPoint}?`;
    if (locationFilter) endPoint += `&location=${locationFilter}`;
    if (cashierFilter) endPoint += `&user=${cashierFilter}`;
    if (createdBy) endPoint += `&createdBy=${createdBy}`;
    if (orderedAtFrom) endPoint += `&orderedAtFrom=${orderedAtFrom}`;
    if (orderedAtTo) endPoint += `&orderedAtTo=${orderedAtTo}`;
    return axios.get(endPoint);
  }

  updateRFIDTool(data) {
    return axios.patch(`${this.jwtConfig.RfidToolEndPoint}`, data);
  }

  /* ------- CREATE RFID CARD    -------- */

  createRFIDCards(data) {
    return axios.post(`${this.jwtConfig.createRfidCard}`, data);
  }

  getListOfAllRfids({ page = 1, limit = 10, searchKeyword = "" }) {
    let endPoint = `${this.jwtConfig.getListofRfids}?page=${page}&limit=${limit}`;
    if (searchKeyword) endPoint += `&title=${searchKeyword}`;
    return axios.get(endPoint);
  }

  getRFIDDetails({ id, page = 1, limit = 10 }) {
    const endPoint = `${this.jwtConfig.getRFIDDetails}/${id}?page=${page}&limit=${limit}`;
    return axios.get(endPoint);
  }

  /** --------- Cashier Shift-------- */

  startCashierShift(data) {
    return axios.post(`${this.jwtConfig.startShift}`, data);
  }

  addCashDShift(data) {
    return axios.post(`${this.jwtConfig.addCashShift}`, data);
  }

  fetchCashierHistory({ id, page, limit }) {
    const endPoint = `${this.jwtConfig.cashierHistory}?userId=${id}&?page=${page}&limit=${limit}`;
    return axios.get(endPoint);
  }

  /* ------- Smart Gates ------ */
  getSmartGatesList({ location, searchKeyword, status, page = 1, limit = 10 }) {
    let endPoint = `${this.jwtConfig.smartGateEndpoint}/list/all?page=${page}&limit=${limit}`;
    if (location) endPoint += `&location=${location}`;
    if (searchKeyword) endPoint += `&searchKeyword=${searchKeyword}`;
    if (status) endPoint += `&status=${status}`;
    return axios.get(endPoint);
  }

  createSmartGate(data) {
    return axios.post(`${this.jwtConfig.smartGateEndpoint}`, data);
  }

  updateSmartGate(data, id) {
    return axios.patch(`${this.jwtConfig.smartGateEndpoint}/${id}`, data);
  }

  deleteSmartGate(id) {
    return axios.delete(`${this.jwtConfig.smartGateEndpoint}/${id}`);
  }

  /* ------- Settings ------ */
  getSettings() {
    return axios.get(`${this.jwtConfig.getSettingsEndPoint}`);
  }

  updateSettings(id, data) {
    return axios.patch(`${this.jwtConfig.updateSettingsEndPoint}/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  /* ------- Company ------ */
  getCompanyDetails() {
    return axios.get(`${this.jwtConfig.getCompanyCurrentEndPoint}`);
  }

  getCompanyDetailsPublic() {
    return axios.get(`${this.jwtConfig.getCompanyDetailsPublicEndPoint}`);
  }

  updateCompanyDetails(id, data) {
    return axios.patch(
      `${this.jwtConfig.updateCompanyDetailsEndPoint}/${id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  /* -------  Product Sales List ------*/
  exportRefundHistory({ id, exportType }) {
    const endPoint = `${this.jwtConfig.exportRefundHistory}/${id}?exportType=${exportType}`;
    return axios.get(endPoint);
  }
}
